import { Link, navigate } from "@reach/router";
import Button from "components/Button";
import InputCheckbox from "components/InputCheckbox";
import InputText from "components/InputText";
import SEO from "components/SEO";
import Wrapper from "components/Wrapper";
import { useTranslation } from "hooks/useTranslation";
import { UserContext } from "hooks/useUser";
import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState
} from "react";
import { FormattedMessage, InjectedIntl, injectIntl } from "react-intl";
import styled from "styled-components";
import { useMutation } from "urql";
import { FieldLocale } from "utils/common/locale";
import { signupMutation } from "utils/queries/user";
import { routes } from "utils/routes";

interface Props {
  pageContext: {
    defaultLocale: String;
    allPathLocales: FieldLocale<string>[];
    pagePath: string;
  };
}

/**
 * Signup page wrapper
 * Just to allow useContext usage inside
 */
const SignupPage: React.SFC<Props> = ({ pageContext }: any) => {
  return <SignupForm pagePath={pageContext.pagePath} />;
};

/**
 * Handle signup process
 */
const SignupForm = injectIntl(
  ({ intl, pagePath }: { intl: InjectedIntl; pagePath: string }) => {
    // Context
    const { loading, user } = useContext(UserContext);
    const { formatMessage } = useTranslation();

    // State variables
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [acceptsMarketing, setAcceptsMarketing] = useState(false);
    const [info, setInfo] = useState("");
    const [error, setError] = useState("");

    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [privacyError, setPrivacyError] = useState(false);

    // Mutations
    const [{}, execute] = useMutation(signupMutation);

    // Effects
    useEffect(() => {
      if (!loading && user) {
        navigate(routes.account);
      }
    }, [loading, user]);

    /**
     * Execute signup
     * In case of error populate the error box
     */
    const handleSignup = async (e: FormEvent) => {
      e.preventDefault();
      if (!isPrivacyChecked) {
        setPrivacyError(true);
        return;
      }
      const response = await execute({
        input: { firstName, lastName, email, password, acceptsMarketing }
      });
      processResponse(response);
    };

    /**
     * Process signup response
     */
    const processResponse = (response: any) => {
      const data = response.data;
      if (data && data.customerCreate && data.customerCreate.customer) {
        setInfo(
          intl.formatMessage({
            id: "success.userCreated",
            defaultMessage:
              "User created! You will receive a confirmation email shortly"
          })
        );
        setError("");
      } else if (
        data &&
        data.customerCreate &&
        data.customerCreate.customerUserErrors
      ) {
        let errorString = "";
        let errors = data.customerCreate.customerUserErrors;
        let error = errors[0];
        if (error.code === "ALREADY_ENABLED" && error.field === "email") {
          errorString = intl.formatMessage({
            id: "error.emailAlreadyRegistered",
            defaultMessage: "The inserted email is already registered"
          });
        } else if (error.code === "TOO_SHORT" && error.field === "password") {
          errorString = intl.formatMessage({
            id: "error.passwordTooShort",
            defaultMessage: "Password is too short (minimum is 5 characters)"
          });
        } else if (error.code === "TOO_LONG" && error.field === "password") {
          errorString = intl.formatMessage({
            id: "error.passwordTooLong",
            defaultMessage: "Password is too long (maximum is 40 characters)"
          });
        } else {
          // Fallback for all other errors - will not be localized
          errorString = error.message;
        }
        setInfo("");
        setError(errorString);
      } else {
        setInfo("");
        setError(
          intl.formatMessage({
            id: "error.customerCreateGeneric",
            defaultMessage:
              "We encountered an error while creating your account"
          })
        );
      }
    };

    return (
      <Container>
        <SEO
          title={formatMessage({ id: "SEO.signupTitle" })}
          link={[
            { rel: "canonical", href: "https://ruedesmille.com" + pagePath }
          ]}
        />
        <Wrapper size="small">
          <Signup onSubmit={handleSignup}>
            <Title>
              <FormattedMessage id="label.createAccount" />
            </Title>
            <Description>
              <FormattedMessage id="label.createAccountDescription" />
            </Description>
            <InputText
              type="text"
              placeholder={intl.formatMessage({
                id: "label.firstName",
                defaultMessage: "First name"
              })}
              value={firstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
            />
            <InputText
              type="text"
              placeholder={intl.formatMessage({
                id: "label.lastName",
                defaultMessage: "Last name"
              })}
              value={lastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
            />
            <InputText
              type="email"
              siqatrib="email"
              placeholder="Email"
              value={email}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <InputText
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
            <InputCheckbox
              id="signup-marketing-checkbox"
              hasError={false}
              isChecked={acceptsMarketing}
              onToggle={() => {
                setAcceptsMarketing(!acceptsMarketing);
              }}
            >
              <FormattedMessage id="label.sendMeNewsletter" />
            </InputCheckbox>
            <InputCheckbox
              id="signup-privacy-checkbox"
              hasError={privacyError}
              isChecked={isPrivacyChecked}
              onToggle={() => {
                setIsPrivacyChecked(old => !old);
                setPrivacyError(false);
              }}
            >
              <FormattedMessage
                id="signup.privacy"
                values={{
                  privacyPolicy: (
                    <Link
                      to={intl.formatMessage({
                        id: "label.privacyUrl",
                        defaultMessage: "/privacy"
                      })}
                    >
                      privacy
                    </Link>
                  )
                }}
              />
            </InputCheckbox>

            {info ? <InfoBox>{info}</InfoBox> : null}
            {error ? <ErrorBox>{error}</ErrorBox> : null}
            <Button
              disabled={!firstName || !lastName || !email || !password}
              type="submit"
            >
              <FormattedMessage id="label.signup" />
            </Button>
          </Signup>
          <Login>
            <Title>
              <FormattedMessage id="label.alreadyHaveAccount" />
            </Title>
            <LinkButtonContainer>
              <Link to={routes.login}>Login</Link>
            </LinkButtonContainer>
          </Login>
        </Wrapper>
      </Container>
    );
  }
);

const Container = styled.div`
  margin-top: 40px;
  ${Wrapper} {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
  }
`;

const Signup = styled.form`
  width: 50%;
  flex: 0 0 50%;
  padding: 50px 80px 50px 0;
  border-right: 1px solid #ededed;
  @media (max-width: 1000px) {
    padding: 50px 40px 50px 20px;
  }
  @media (max-width: 800px) {
    padding: 50px 20px 50px 0px;
  }
  @media (max-width: 700px) {
    border-right: none;
    border-bottom: 1px solid #ededed;
    width: 100%;
    padding: 40px 20px;
  }
  @media (max-width: 500px) {
    padding: 0 0 40px 0;
  }
`;

const Login = styled.div`
  width: 50%;
  flex: 0 0 50%;
  padding: 50px 0 50px 80px;
  @media (max-width: 1000px) {
    padding: 50px 20px 50px 40px;
  }
  @media (max-width: 800px) {
    padding: 50px 0px 50px 20px;
  }
  @media (max-width: 700px) {
    border-right: none;
    width: 100%;
    padding: 40px 20px;
  }
  @media (max-width: 500px) {
    padding: 40px 0 0 0;
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: #977b2b;
  letter-spacing: 0.16em;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    text-align: center;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 14px;
  color: #706f6f;
  line-height: 1.8em;
  letter-spacing: 0.06em;
  padding-bottom: 30px;
  @media (max-width: 400px) {
    text-align: center;
  }
`;

const LinkButtonContainer = styled.div`
  margin-top: 30px;
  @media (max-width: 400px) {
    text-align: center;
  }
  a {
    display: inline-block;
    background-color: #977b2b;
    outline: inherit;
    font-size: 14px;
    letter-spacing: 0.16em;
    font-weight: 600;
    width: 100%;
    max-width: 220px;
    text-transform: uppercase;
    font-family: soleil, sans-serif;
    transition: 0.3s all;
    color: #fff;
    width: 100%;
    height: 50px;
    text-align: center;
    text-decoration: none;
    padding-top: 13px;
    &:hover {
      cursor: pointer;
      background-color: #806724;
    }
  }
`;

const ErrorBox = styled.div`
  color: #dd4f4f;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 30px;
  box-sizing: border-box;
  @media (max-width: 400px) {
    text-align: center;
  }
`;

const InfoBox = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 30px;
  box-sizing: border-box;
  @media (max-width: 400px) {
    text-align: center;
  }
`;

export default SignupPage;
